@media screen and (max-width: 1500px) {
  .logo-side img {
    width: 190px !important;
  }
  aside {
    width: 270px;
  }
  .right-side-cmn {
    width: calc(100% - 270px);
  }
  .logo-side img {
    width: 100px;
  }
  .side-menu a {
    font-size: 16px;
  }
  .heading-top h2 {
    font-size: 27px;
  }
  .next-btn-fix {
    width: calc(100% - 270px);
  }
  .cmn-top-fields select {
    max-width: 200px;
  }
  .logo {
    margin-left: 50px;
  }
  .side-menu .side-btm-last {
    margin-top: 20px;
  }
  .product-cont-boxes {
    padding: 20px 27px;
  }
  .product-overview-box {
    padding: 30px 35px;
  }
  .table-spacing-between {
    width: 300px;
    max-width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .logo-side img {
    width: 150px !important;
  }
  .dash-inner-boxes {
    padding: 25px 18px;
  }
  .dashbox-inner-wrap {
    padding: 20px 20px;
  }
  .dashbox-side {
    padding: 20px 20px;
  }
  .dash-inner-boxes h4 {
    margin-top: 10px;
  }
  .cmn-btn a {
    padding: 11px 30px;
  }
  .product-cont-boxes {
    padding: 20px 15px;
  }
  .product-overview-box {
    padding: 28px 25px;
  }
  .logo-side img {
    width: 100px;
  }
  aside {
    width: 225px;
  }
  .right-side-cmn {
    width: calc(100% - 225px);
  }
  .next-btn-fix {
    width: calc(100% - 225px);
  }
  .product-cmn-color .cmn-top-fields select {
    max-width: 100%;
  }
  .toggle-icon {
    left: 180px;
  }
  .property-name-show.customer img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .side-menu .side-btm-space {
    margin-bottom: 10px;
  }
  .side-menu .side-btm-last {
    margin-top: 20px;
  }
  .side-menu a {
    padding: 5px 10px;
  }
  .cmn-btn a {
    padding: 10px 30px;
  }
  .transactions-box {
    padding: 20px 15px;
  }
  .dashbox-inner-wrap {
    padding: 20px 15px;
  }
  .dash-inner-boxes {
    padding: 20px 18px;
  }
  .dash-inner-boxes h4 {
    font-size: 20px;
  }
  .dash-inner-boxes p {
    margin-top: 13px;
  }
  .dash-inner-boxes {
    margin-top: 17px;
  }
  .dashbox-side h2 {
    margin-bottom: 36px;
  }
  .dash-graph img {
    width: 100%;
    height: 100%;
  }
  .transactions-box h2 {
    margin-top: 12px;
    font-size: 20px;
  }
  .cmn-btn a {
    padding: 10px 25px;
  }
  .cmn-top-fields select {
    padding-left: 10px;
    font-size: 13px;
  }
  .login-cmn-box {
    max-width: 700px;
  }
  .login-box-inner-wrap {
    max-width: 550px;
    margin: auto;
    padding: 35px 0;
  }
  .submit {
    margin-top: 30px !important;
  }
  .logo {
    margin-left: 20px;
  }
  .product-cont-boxes h2 {
    font-size: 22px;
  }
  .product-cont-boxes {
    padding: 15px 15px;
  }
  .table-spacing-between {
    width: 150px;
    max-width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .table-cmn.table.table-sm {
    overflow-x: scroll;
    width: 975px;
  }
  aside {
    width: 250px;
  }
  .right-side-cmn {
    width: calc(100% - 225px);
  }
  .logo-side img {
    width: 100px;
  }
  .side-menu a {
    font-size: 15px;
  }
  .next-btn-fix {
    position: unset;
    width: 100%;
  }
  table.table-cmn.table.table-sm {
    margin-bottom: 76px;
  }
  .cmn-btn a {
    padding: 10px 15px;
  }
  .transaction-graph img {
    width: 100%;
    height: 100%;
  }
  .dashbox-side {
    padding: 20px 15px;
  }
  .dash-inner-boxes {
    padding: 15px 10px;
    height: auto;
  }
  .dashbox-inner-wrap h2 {
    font-size: 14px;
  }
  .dashbox-side h2 {
    font-size: 14px;
  }
  .cmn-top-fields .col {
    flex: none;
    width: calc(100% / 3);
  }
  .cmn-top-fields select {
    max-width: 100%;
  }
  .product-tab-cmn-btns .buttons {
    padding: 8px 15px;
  }
  .table-spacing-between {
    width: 10px;
    max-width: 100%;
  }
  .cmn-btn.transactions {
    margin-top: 20px;
  }
  aside {
    width: 225px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .transaction-graph img {
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .login-box-inner-wrap {
    max-width: 550px;
    margin: auto;
    padding: 45px 65px;
  }
  .cmn-btn a {
    padding: 10px 20px;
  }
  .cmn-top-fields .col {
    flex: none;
    width: calc(100% / 2);
  }
  .box-center.row {
    height: 100%;
    margin-top: 20px;
    align-items: center;
  }
  .logo {
    margin: auto;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .login-box-inner-wrap {
    padding: 45px 30px;
  }
  .cmn-top-fields .col {
    flex: none;
    width: 100%;
  }
}
