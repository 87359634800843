.user-profile-main figure* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h2 {
  font-family: "Mont";
}

body {
  background: #fbfbfb !important;
  font-family: "Mont";
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.login-box-inner-wrap h2 {
  color: #000;
  font-family: "Mont";
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 32px;
  letter-spacing: -0.6px;
}

.form-set {
  display: flex;
  height: 164px;
  flex-direction: column;
  justify-content: center;
}

.login-cmn-box {
  margin: auto;
  max-width: 750px;
  border: 1px solid #f0c023;
  background: #f0c0232e;
}

.login-box-inner-wrap {
  max-width: 550px;
  margin: auto;
  padding: 45px 0;
  min-height: 450px;
}

.login-box-inner-wrap form {
  margin-top: 35px;
}

.login-box-inner-wrap label {
  color: #000;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 0;
  height: 42px;
}

.login-box-inner-wrap input::placeholder {
  color: #071523;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.submit {
  margin-top: 40px !important;
  border: 0;
  background: #f0c023;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  width: 150px;
  height: 42px;
  text-decoration: none;
  margin: auto;
}

.forgot {
  color: #000;
  text-align: center;
  font-family: "Mont";
  font-size: 15px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
  margin-top: 20px;
}

.login-box-inner-wrap input:focus {
  box-shadow: none;
}

.logo {
  margin-left: 90px;
  margin-top: 20px;
}

.box-center.row {
  height: calc(100vh - 240px);
  align-items: center;
}

aside {
  border-right: 1px solid #d9d9d9;
  background: #fff;
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1;
}

.logo-side img {
  width: 200px;
  margin: 25px 23px;
}

.side-menu a {
  color: #000;
  display: flex !important;
  font-family: "Mont";
  font-size: 15px;
  padding: 10px 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  display: block;
}

.side-menu a svg {
  margin-right: 13px;
  width: 17px;
}

.side-menu a p {
  margin: 0;
}

/* .sidebar {
    padding: 0 30px;
} */

.side-menu .side-btm-space {
  margin-bottom: 5px;
}

.side-menu .side-btm-last {
  margin-top: 40px;
}

.main-wrap {
  display: flex;

  height: 100vh;
}

.height-set {
  height: 100%;
}

.right-side-cmn {
  width: calc(100% - 270px);
  position: relative;
  margin-left: auto;
  background: #fbfbfb;
  height: 100vh;
}

.right-top {
  background: #f0c023;
}

.heading-top h2 {
  color: #ffffff;
  font-family: "Mont";
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.6px;
  margin: 0;
}

.heading-top {
  padding: 25px 20px;
}

.right-top hr {
  color: rgba(255, 255, 255, 0.2);
  margin: 1px !important;
  opacity: 1;
}

.cmn-top-fields {
  padding: 20px 7px;
}

.cmn-top-fields input {
  background: #fff;
  border-radius: 0;
  font-size: 14px;
  height: 40px;
  padding-left: 20px;
}

.cmn-top-fields input::placeholder {
  color: #000;
  font-family: "Mont";
  font-size: 14px;

  font-weight: 400;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
}

.cmn-top-fields input:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

.table-cmn tr td p {
  margin: 0;
  color: #071523;
}

.cmn-top-fields select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

.cmn-top-fields select {
  color: #000;
  font-family: "Mont";
  height: 40px;
  max-width: 270px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
}

.cmn-btn a {
  border: 1px solid #cb3534;
  background: #cb3534;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
  border-radius: 8px;
}

.product-tab ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
}

.product-tab ul li {
  list-style: none;
  padding: 0;
}

.product-tab {
  padding: 12px 10px;
}

.product-tab ul li a {
  color: rgb(255 255 255 / 50%);
  font-family: "Mont";
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  padding: 15px 0px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
  margin-right: 35px;
}

.product-tab .active-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  height: 2px;
}

.product-tab .active-tab {
  color: #ffffff !important;
}

.product-tab ul li a span {
  margin-left: 10px;
}

.show-results {
  color: #000;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.select-result span {
  color: #000;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
  padding-right: 20px;
}

.select-result select {
  padding: 4px 10px;
  width: 55px;
  border: 0.5px solid #d8d7ce;
  background: #fff;
  border-radius: 0;
  color: #000;
  font-family: "Mont";
  font-size: 13px;
  background-repeat: no-repeat;
  background-position: center right 3px;
  font-weight: 400;
  background-image: url("../Images/Expand_down.svg");
  letter-spacing: -0.3px;
}

.select-result select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

.results-sec {
  padding: 10px 10px;
}

.table-cmn tr th {
  padding: 12px 12px;
  background: #f3f5f9;
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
}

.table-cmn tr td {
  padding: 8px 12px;
  color: #000;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  text-align: center;
}

.table-cmn tr td a {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.table-cmn tr th:first-child {
  padding-left: 23px;
}

.table-cmn tr td:first-child {
  padding-left: 23px;
}

.next-btn-fix {
  background: #fff;
  text-align: end;
  position: fixed;
  width: calc(100% - 270px);
  padding: 20px 40px;
  bottom: 0;
  border-top: 1px solid #e9edf7;
}

.next-btn-fix a {
  padding: 7px 20px;
  border: 0;
  background: #f0c02314;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 0%);
  color: #f0c023 !important;
  font-family: "Mont";
  font-size: 15px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
}

.back-white {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.inner-tab-cmn a {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;
  text-align: center;
  font-family: "Mont";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}

.inner-tab-cmn .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.transactions-box {
  margin-top: 16px;
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 30px;
}

.transactions-box h4 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: -0.3px;
}

.transactions-box h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 24px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
  margin-top: 12px;
}

.transaction-graph img {
  width: 100%;
  height: 224px;
}

.transaction-graph {
  margin-top: 15px;
}

.graph-months {
  display: flex;
  justify-content: space-between;
  max-width: 340px;
  margin: auto;
}

.graph-months h5 {
  color: rgba(65, 65, 65, 0.4);
  font-family: Poppins;
  font-size: 12.171px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.transaction-box-wrap {
  padding: 0 10px;
}

.dashbox-inner-wrap {
  border: 1px solid #6a6d60;
  background: #fff;
  padding: 25px 25px;
}

.dashbox-inner-wrap h2 {
  color: #6a6d60;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.dashbox-inner-wrap select.form-select {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #000;
  font-family: "Mont";
  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.dashbox-inner-wrap select.form-select:focus {
  box-shadow: none;
}

.dash-inner-boxes {
  background: #f0c0232e;
  box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 15%);
  padding: 25px 22px;
  margin-top: 10px;
  min-height: 160px;
}

.dash-inner-boxes h6 {
  color: #071523;
  font-family: "Mont";
  text-transform: uppercase;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.dash-inner-boxes h4 {
  color: #071523;
  font-family: "Mont";
  font-size: 24px;
  font-style: normal;
  margin-top: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.dash-inner-boxes p {
  color: #979797 !important;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 0;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.dashbox-side {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 25px 25px;
  height: 100%;
}

.dashbox-side h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 56px;
  letter-spacing: -0.4px;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.dash-graph img {
  width: 100%;
  height: 500px;
}

.dash-bottom {
  margin-top: 30px !important;
}

.table-cmn.table.table-sm {
  margin-bottom: 64px;
}

.side-menu a:hover {
  background: #f0c0232e;
}

.side-menu a.bar_active {
  background: #f0c0232e;
  color: #5f6368;
}

.heading-top-inner {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 30px 20px 30px 20px;
}

.heading-top-inner h2 {
  margin: 0;
  color: #ffffff;
  font-family: "Mont";
  font-size: 30px;
  font-weight: 700;

  line-height: 32px;
  letter-spacing: -0.6px;
}

.heading-top-inner p {
  margin: 0;
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  margin-top: 3px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.heading-top-inner h4 {
  color: #071523;
  text-align: right;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.3px;
  margin: 0;
}

.heading-top-inner h2 {
  margin: 0;
}

.product-tab-cmn-btns .buttons {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}

.product-tab-cmn-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-right: 8px;
}

.product-tab-left a {
  color: #40413a;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.product-tab-left a svg {
  margin-right: 10px;
}

.product-cmn-tab {
  margin: 10px 0;
}

.product-tab-cmn-btns .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 15px 20px;
}

.product-cont-boxes h6 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.product-cont-boxes h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 26px;
  margin-top: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 35px;
  margin-bottom: 20px;
}

.product-graph img {
  width: 100%;
}

.product-table-top {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 15px 0;
  margin-top: 20px;
}

.product-table-top h3 {
  color: rgba(32, 33, 36, 0.69);
  text-align: center;
  font-family: "Mont";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.table-cmn .total-amount {
  color: #3eaf3f;
  font-weight: 600;
}

.table-cmn .balance {
  color: #000;
  font-weight: 600;
}

h2.property {
  font-weight: 700;
}

.product-overview-box {
  background: #fff;
  padding: 30px 50px;
  border: 0.25px solid #6a6d60;
}

.product-overview-box h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 30px;
}

.product-overview-bottom h3 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}

.product-overview-bottom p {
  color: #071523;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}

.bottom-down button {
  color: #071523;
  text-align: right;
  font-family: "Mont";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}

.bottom-top button {
  color: #071523;
  text-align: right;
  font-family: "Mont";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}

.product-overview-box .edit-product {
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  background: linear-gradient(180deg, #3457d5 0%, #6cb4ee 86.29%) !important;
  display: block;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: "Mont";
  font-size: 13px;
  margin-top: 8px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
}

.product-overview-bottom .bottom-top {
  margin-bottom: 15px;
}

.product-overview-bottom .bottom-down {
  margin-bottom: 15px;
}

.product-cmn-color {
  background: #d9d9d9;
}

.product-cmn-color hr {
  color: #e9edf7;
  margin: 1px !important;
  opacity: 1;
}

.table-spacing-between {
  width: 500px;
  max-width: 100%;
}

.transact-amount h3 {
  color: rgba(32, 33, 36, 0.69);
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}

.transact-amount span {
  color: rgba(32, 33, 36, 0.69);
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-left: 6px;
}

.product-transactions .transact-amount {
  display: flex;
}

.product-transactions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 10px;
}

.product-transactions .transact-amount:nth-child(2) {
  margin-left: 30px;
}

.heading-top-inner h2 img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 67px;
  margin-right: 10px;
}

.customer-form {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.customer-form h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
}

.customer-form label {
  color: #000;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.customer-form input {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}

.customer-form input::placeholder {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.customer-form input:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.profile-products-sec {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
}

.profile-products-sec h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
  margin-left: 50px;
}

.product-inner h3 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.product-inner p {
  color: #071523;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px !important;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}

.product-inner-right button {
  color: #000;
  text-align: center;
  font-family: "Mont";
  font-size: 12px;
  font-weight: 400;
  border: none;
  padding: 4px 50px;
  background: #d9d9d9;
}

.product-inner-right p {
  color: #071523;
  text-align: right;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-top: 10px;
  margin-bottom: 0;
}

.product-main-box {
  padding: 0 50px;
}

.product-inner-right {
  text-align: end;
}

.product-inner-right .closed-btn {
  background-color: #000;
  color: #fff;
  font-weight: 300;
}

.admin-box-top h3 {
  text-align: right;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  color: #071523;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.admin-box-inner p {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-align: justify;
}

.admin-box-main {
  padding: 0 50px;
}

.admin-box-top h3 span {
  margin: 0 10px;
}

.admin-box-inner textarea {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #071523;
  font-size: 13px;
  font-weight: 400;
}

.admin-box-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.admin-box-inner textarea::placeholder {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.admin-box-main hr {
  margin: 20px 0;
}

.product-actions {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
}

.product-actions h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  padding-left: 70px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.product-action-inner {
  padding: 0 50px;
}

.product-action-inner h3 {
  color: #000;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}

.product-action-inner p {
  color: #000;
  text-align: right;
  font-family: "Mont";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.product-actions .add-btn {
  margin-right: 50px;
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  padding: 10px 30px;
}

.cmn-border {
  color: #dadfdd;

  margin: 1px !important;
  opacity: 1;
}

.cmn-background {
  background: #d9d9d9;
}

.table-cmn .total-amount-withdraw {
  color: #e14942;
  font-weight: 600;
}

.form-save-btn button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;
  font-family: "Mont";
  padding: 11px 58px;
  font-size: 13px;
  font-weight: 400;
}

.customer-form-new h2 {
  color: #6a6d60;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
  margin-top: 35px;
  padding: 0 50px;
}

.customer-form-inner {
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.customer-form-new {
  background-color: #fff;
  border: 1px solid #979797;
}

.customer-form-new label {
  color: #6a6d60;
  font-family: "Mont";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.customer-form-new input {
  border: 0.25px solid #6a6d60;
  background: #fff;
  padding: 10px 10px;
  color: #6a6d60;
  font-size: 13px;
  border-radius: 0;
}

.customer-form-new input::placeholder {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.customer-form-new input:focus {
  box-shadow: none;
  border: 1px solid #6a6d60;
}

.customer-form-new select {
  border: 1px solid #6a6d60;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}

.customer-form-new select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}

.form-save-btn-new button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;
  font-family: "Mont";
  padding: 8px 70px;
  font-size: 13px;
  font-weight: 400;
}

.product-overview-box .edit-product-new {
  background: #fff;
  display: block;
  width: 100%;
  color: #071523;
  text-align: center;
  font-family: "Mont";
  font-size: 13px;
  margin-top: 8px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
  border: 0.25px solid #d9d9d9;
}

.member-info-inner h2 {
  margin-bottom: 10px;
}

.member-inner p {
  color: #071523;
  margin-bottom: 4px;
  font-family: "Mont";
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 400;
}

.address-main p {
  color: #071523;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.member-inner p:first-child span {
  color: #1462d7;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: -0.3px;
}

.product-overview-box select {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #071523;
  text-align: center;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  padding: 8px;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
}

.product-overview-box select:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.cmn-btn button {
  border: 1px solid #cb3534;
  background: #cb3534;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  color: #fff;
  text-align: center;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 65px;
}

.product-action-inner h3 a {
  color: #1462d7;
}

.access-select-box {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin-bottom: 8px;
  align-items: center;
}

.access-select-box h3 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
}

.access-select-box .inner h3 {
  margin-left: 30px;
  width: 23px;
}

.access-select-box .form-check-inline {
  margin-right: 0;
  margin-left: 30px;
}

.access-select-box p {
  margin-bottom: 0;
  color: #000;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.access-select-box .form-check-input {
  border-radius: 5px !important;
  border: 1px solid #979797;
  background: #fff;
}

.access-select-box .form-check-input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}

.input-image-show {
  border: 0.25px dashed #979797;
  background: #fff;
  width: 100%;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input-image-show p {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.input-image-show input {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
}

.blog-save-btn {
  text-align: center;
  margin-top: 44px;
}

.blog-save-btn button {
  padding: 12px 65px;
}

.product-overview-box.blog-bottom {
  padding-bottom: 50px;
}

.check-box-inner {
  display: flex;
  margin-right: 20px;
  align-items: center;
}

.check-box-inner form {
  margin-left: 10px;
}

.check-box-inner form input {
  border-radius: 5px !important;
  border: 1px solid #97979785;
  background: #fff;
  width: 20px;
  height: 20px;
}

.check-box-inner form input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}

.create-mail-checks {
  display: flex;
  align-items: center;
}

.customer-form-inner textarea {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}

.customer-form-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.dash-graph.right {
  margin-right: 12px;
}

.user-img img {
  width: 50px;
  border-radius: 70px;
  height: 50px;
  object-fit: cover;
}

.select-box select {
  width: fit-content;
  border: 1px solid #dee2e6;
  border-radius: 0;
  color: #000;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.select-box select:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.order-btn a {
  border: 1px solid #979797;
  background: #fff;
  color: #979797 !important;
  width: 85px;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.accept-reject-btn button {
  background: linear-gradient(180deg, #1ba97d, #118d67);
  border: 1px solid #1ba97d;
  box-shadow: 0 10px 14px 0 #1ba97d66;
  color: #fff;
  text-align: center;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 90px;
  border-radius: 0;
  margin: 0 10px;
}

.accept-reject-btn {
  display: flex;
  justify-content: center;
}

.admin-box-select select {
  border-radius: 0;
  color: #6a6d60;

  font-weight: 600;
  font-size: 14px;
  height: 40px;
  letter-spacing: -0.3px;
  line-height: 24px;
  /* max-width: 270px; */
  padding-left: 20px;
}

.customer-form-inner textarea::placeholder {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}

.customer-form-new input input::placeholder {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}

.customer-form-inner .admin-box-select select {
  appearance: auto;
}

.admin-box-select select:focus {
  box-shadow: none;
}

.account-btn {
  border: 1px solid #979797 !important;
  background: #fff !important;
  color: #979797 !important;
  color: #fff;
  text-align: center;
  font-family: "Mont";
  margin: 0 2px !important;
  font-size: 13px !important;
  font-style: normal;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  line-height: normal;
  text-decoration: none;
  padding: 8px 33px !important;
  border-radius: 0 !important;
  margin: 10px 0;
}

.account-btn:hover {
  border: 1px solid #979797;
  background: #fff;
  color: #979797 !important;
}

.theme-colr-btn:hover {
  color: #ffffff !important;
}

.common-colr-btn:hover {
  color: #ffffff !important;
}

.add-btn {
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  color: #fff !important;
  text-align: center;
  border: 1px solid #cb3534;
  background: #cb3534;
  font-family: "Mont";
  font-size: 13px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 70px;
}

.customer-form-inner-new {
  border: 0.25px solid #d9d9d9;
  padding: 20px 20px;
}

.support-chat-box-main {
  padding: 22px;
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

.user-top-hdng {
  display: flex;
  align-items: center;
}

.user-top-hdng img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 10px;
}

.user-top-hdng h3 {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0px;
}
.add-some-width h6 {
  font-size: 12px;
  text-align: right;
  padding-top: 9px;
}

.user-top-hdng h4 {
  font-size: 11px;
  margin: 0;
}
.add-some-width {
  width: 100%;
  max-width: 222px;
}

.user-msg-box {
  background: #e7e7e7;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 20px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 1px;
  margin-top: 4px;
  padding: 8px 9px;
  width: 100%;
}

.user-msg-box p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.support-inner h2 {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}

.support-msg-box {
  padding: 20px 10px;
  height: 55vh;
  overflow-y: scroll;
}

.user-box {
  margin-bottom: 15px;
}

.send-box {
  bottom: 0;
  position: absolute;
  width: 95%;
}

.send-feild {
  background: #fbfbfb !important;
  border: none !important;
  padding: 15px !important;
}

.send-box input:focus {
  box-shadow: none;
}

.dash-graph {
  border: 0.25px solid #6a6d60;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 25px;
}

.toggle-icon {
  position: fixed;
  left: 245px;
  border: 2px solid #fff;
  padding: 5px 6px;
  background: #f0c023;
  filter: drop-shadow(0px 6px 10px rgba(151, 151, 151, 0.47));
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}

.toggle-icon img {
  width: 20px;
  filter: invert(1);
}

.left-side.hide aside .side-menu a p {
  display: none;
}

.left-side.hide aside {
  width: 100px;
}

.right-side-cmn.hide {
  width: calc(100% - 100px);
  /* background: #eff8f3; */
}

.left-side.hide .toggle-icon {
  transform: rotateY(180deg);
  left: 78px;
}

.left-side.hide .side-menu a {
  justify-content: center;
}

.right-side-cmn.hide .next-btn-fix {
  width: calc(100% - 100px);
}

.user-profile-main figure {
  margin: 0;
  align-items: center;
}

.user-profile-main img {
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  height: 150px;
}

.user-profile-main h2 {
  padding-bottom: 6px;
  margin: 0;
  color: rgb(0 0 0 / 100%);
  font-size: 20px;
  font-weight: 700;
}

.user-profile-main p {
  margin: 0;
  font-size: 14px;
  padding: 2px 0;
  font-weight: 400;
  color: #40413a;
}

.user-profile-main figcaption {
  margin-left: 20px;
}

.logo-side .small {
  display: none;
}

.left-side.hide .logo-side .small {
  display: block;
  width: 40px !important;
}

.left-side.hide .logo-side .big {
  display: none;
}

.user-profile-main {
  padding: 20px 15px;
  background-color: #fff;
  border: 0.25px solid #6a6d60;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
}

.send-box button img {
  transform: rotatey(180deg);
  filter: invert(1);
}

.logo-side .small {
  width: 40px;
}

.user-profile-main p span {
  font-weight: 700;
  color: rgba(7, 21, 35, 0.7);
}

.user-profile-all {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.user-profile-all h3 {
  text-align: center;
  color: #6a6d6f;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.user-profile-all span {
  font-weight: 700;
}

.dash-graph .gender-chart {
  height: 400px !important;
  margin: auto;
  max-width: 400px !important;
}

.product-overview-box p {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  padding: 10px 10px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 4px;
  font-family: "Mont";
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
}

.admin-box-select label {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 700;
}

.dash-graph h6 {
  color: #6a6d60;
  font-family: "Mont";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.customer-form.profile h2 {
  font-size: 18px;
  font-weight: 600;
}

.property-name-show {
  width: fit-content;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  border-radius: 10px;
  background: linear-gradient(180deg, #6c9459 0%, #39562e 100%);
  box-shadow: 0px 5px 15px 0px rgba(27, 169, 125, 0.2);
  margin-top: 15px;
}

.property-name-show img {
  width: 65px;
  border-radius: 10px;
  height: 64px;
  margin-right: 10px;
}

.property-name-show h2 {
  color: #fff !important;
  font-family: "Mont";
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.property-name-show h3 {
  color: #fff;
  font-family: "Mont";
  font-size: 14px;
  font-style: normal;
  opacity: 0.7;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.property-main h2 {
  color: #071523;
  text-transform: uppercase;
  font-family: "Mont";
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.3px;
}

.property-main {
  margin-top: 35px;
  margin-left: 12px;
}

.dashboard-items.profile .dash-inner-boxes {
  margin-top: 10px;
}

.property-name-show h3 {
  margin: 0;
  padding: 0;
  border: 0;
}

.assign {
  border: 1px solid #c1dece;
  padding: 17px 10px !important;
  font-size: 13px !important;
  text-transform: uppercase;
}

.logo img {
  width: 150px;
}

.dash-inner-boxes.vendor {
  margin: 0;
}

.status-box h2 {
  margin: 0;
  color: #fff;
}

.status-box {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  width: 200px;
  border-radius: 8px;
  padding: 9px 18px;
  margin-top: 20px;
  margin-right: 10px;
}

.status-box p {
  color: #fff;
}

.add-btn-category {
  color: #fff;
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  width: 97px;
  font-size: 14px;
  height: 36px;
  border-radius: 0;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.change-pass {
  height: 100%;
  margin-top: 30px;
}

.property-name-show.view h2 {
  margin: 0;
}

.bar_active .analytics-icon path {
  fill: #5f6368 !important;
}

a.menu-btn.bar_active path {
  fill: #f0c023 !important;
}

.analytics-icon path {
  fill: #000 !important;
}

.property-name-show.customer {
  background: none;
  box-shadow: none;
}

.property-name-show.customer h2 {
  color: rgba(7, 21, 35, 0.7) !important;
  font-weight: 600;
}

.property-name-show.customer h3 {
  font-weight: 600;
  color: #979797;
}

.property-name-show.customer img {
  width: 130px;
  height: 115px;
}

.dash-inner-boxes.small {
  height: 100px !important;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customer-form-new.card h2 {
  color: #071523;
  font-family: "Mont";
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.login-box-inner-wrap input:focus {
  border: 1px solid #c1dece;
}

.user-profile-main h3 {
  color: #071523;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.admin-box-select select:focus {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.property-name-show.view {
  background: none;
  box-shadow: none;
}

.property-name-show.view h2 {
  color: #3a7563 !important;
  font-family: "Mont";
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
}

.menu-btn.bar_active .dash-only path {
  stroke: none !important;
  fill: #5f6368 !important;
}

.table-cmn.manager img {
  width: 60px;
  height: 60px;
}

.table-cmn td {
  display: table-cell;
  vertical-align: middle;
}

.send-box .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background: #cb3534;
  border: 0;
  border-radius: var(--bs-border-radius);
}

.support-msg-box {
  -ms-overflow-style: none !important;
  /* for Internet Explorer, Edge */
  scrollbar-width: none !important;
  /* for Firefox */
  overflow-y: scroll;
}

.support-msg-box::-webkit-scrollbar {
  display: none !important;
  /* for Chrome, Safari, and Opera */
}

/* Profile view css  start  */

.user-profile-main hr {
  margin: 30px 0px;
}

.profile-hours {
  display: flex;
  justify-content: space-between;
}

.dlt-ac-btn a {
  color: #fff !important;
  border: 0 !important;
  background: #cb3534;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  padding: 11px 33px;
}

.user-profile-main figcaption input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.user-profile-main figcaption .form-check-input {
  height: 22px;
}

.user-profile-main figcaption .form-switch .form-check-input {
  width: 3em;
  border: var(--bs-border-width) solid #b9b5ab;
}

.user-profile-main figcaption .form-check-input:checked {
  background-color: #b9b5ab;
}

.user-profile-main figcaption .form-switch .form-check-input:focus {
  filter: grayscale(1);
}

/* Profile view css End  */

.default-links ul {
  padding: 0;
  list-style: none;
}

.default-links ul li {
  display: flex;
  border-bottom: 1px solid #b9b5ab6e;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}

.default-links ul li h6 {
  color: #071523;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.default-links ul li p {
  font-size: 13px;
}

.profile-add-img {
  width: 100% !important;
}

.active-default-links ul {
  padding: 0;
  list-style: none;
}

.active-default-links ul li {
  border-bottom: 1px solid #b9b5ab6e;
  justify-content: space-between;
  padding: 8px 0px;
}

.active-default-links ul li h6 {
  color: rgba(7, 21, 35, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
}

.active-default-links ul li p {
  text-align: end;
  font-size: 13px;
}

.cmn-btn a svg {
  height: 18px;
  width: 18px;
  margin-right: 12px;
}

.theme-colr-btn {
  border: 1px solid #cb3534 !important;
  background: #cb3534 !important;
  color: #fff !important;
}

.common-colr-btn {
  border: 1px solid #cb3534 !important;
  background: #cb3534 !important;
  color: #fff !important;
}

.select-box {
  display: flex;
  justify-content: center;
}

.view-chat a {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}

.modal-close {
  border: 1px solid #b9b5ab !important;
  background: #b9b5ab !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: none !important;
  padding: 11px 33px !important;
}

.modal-open-view {
  border: 1px solid #b9b5ab !important;
  background: #071523 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: none !important;
  padding: 11px 33px !important;
}

.no-conversations {
  background: #eaeaea;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-none {
  border: none !important;
}

.chat-box-select select {
  border-radius: 0;
  color: #6a6d60;
  font-weight: 600;
  font-size: 14px;
  height: 45px;
  letter-spacing: -0.3px;
  line-height: 24px;
  width: 350px;
  padding-left: 20px;
}

.side-menu a.bar_active p {
  color: #f0c023 !important;
}

.table-responsive {
  height: 55vh;
}

.no-data-updated {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50vh;
  align-items: center;
}

p.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}

.are-sure-text {
  font-size: 24px;
  margin: 40px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px !important;
}

.theme-btn {
  /* border: 1px solid #c651ff !important; */
  /* background-color: #c651ff !important; */
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff !important;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 24px;
  border-radius: 0 !important;
  border: 1px solid #cb3534 !important;
  background: #cb3534 !important;
  color: #fff !important;
}

.next-btn-fix ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 5px;
  margin: 10px 0px;
}

.set-pagi-btm .previous a {
  border: 1px solid #f3f5f9;
  background: #f0c02314;
  color: #f0c023 !important;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 0%);
  border-radius: 10px 0px 0px 10px;
  font-size: 14px;
}

.set-pagi-btm .previous a:active {
  background: #f0c023;
  color: #fff !important;
}

.set-pagi-btm .next a {
  border: 1px solid #f3f5f9;
  background: #f0c02314;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 0%);
  border-radius: 0px 10px 10px 0px;
  font-size: 14px;
  color: #f0c023 !important;
}

.set-pagi-btm .next a:active {
  background: #f0c023;
  color: #fff !important;
}

.next-btn-fix .selected a {
  padding: 8px 20px;
  border: 0;
  background: #f0c023;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff !important;
  font-family: "Mont";
  font-size: 18px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
}



.privacy_text h1 {
  font-size: 40px;
  font-weight: 700;
}

.privacy_text h4 {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 0px;
}

.privacy_text h2 {
  padding-top: 5px;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 0px;
}

.privacy_text h3 {
  font-size: 22px;
  font-weight: 700;
}

.privacy_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
}

.privacy_text {
  padding-bottom: 50px;
  padding-top: 50px;
}

.container-tem {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sub_points {
  list-style: decimal;
  padding: 0 0 0 25px;
}

.privacy_text table,
th,
td {
  border: 1px solid black !important;
  text-align: left !important;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}
.closed-status {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  min-width: 28px !important;
  max-width: 77px;
  border-radius: 2px !important;
}